import { graphql } from 'gatsby'
import React from 'react'
import { Banner, Layout, OurStore, OurExperience } from '../components'
import PropTypes from 'prop-types'
import { getLayoutDataFromWebsiteData, getWebsiteData } from '../utils'

const Index = ({ location, data }) => {
	const cmsData = getWebsiteData(data)
	const pageData = cmsData.aboutUsPage
	const layoutData = getLayoutDataFromWebsiteData(cmsData)
	const bannerProps = {
		image: pageData.bannerImage,
		title: pageData.bannerTitle,
		titleShadow: pageData.bannerTitleShadow,
	}

	const ourExperienceProps = {
		title: pageData.ourExperienceTitle,
		titleShadow: pageData.ourExperienceTitleShadow,
		content: pageData.ourExperienceContent,
		images: pageData.ourExperienceImages,
		details: pageData.ourExperienceDetails,
	}
	const ourStoreProps = {
		title: pageData.ourStoreTitle,
		titleShadow: pageData.ourStoreTitleShadow,
		content: pageData.ourStoreContent,
		image: pageData.ourStoreImage,
		waze: cmsData.waze,
		workingHours: cmsData.workingHours,
	}

	return (
		<Layout
			location={location}
			SeoProps={{ title: pageData.name, description: pageData.description }}
			{...layoutData}
		>
			<Banner {...bannerProps} />
			<OurExperience {...ourExperienceProps} />
			<OurStore {...ourStoreProps} />
		</Layout>
	)
}
Index.propTypes = {
	location: PropTypes.object,
	data: PropTypes.any,
}
export default Index
export const query = graphql`
	query q3 {
		allSanityWebsite(filter: { name: { eq: "Superba Website" } }) {
			edges {
				node {
					workingHours
					whatsappNumber
					socialsLinkedin
					socialsInstagram
					socialsFacebook
					phoneNumber
					waze
					email
					contactUsTitleShadow
					digitalCatalogueLocation
					contactUsTitle
					contactUsContent
					buttonContent
					address
					aboutUsPage {
						name
						description
						bannerImage {
							asset {
								gatsbyImageData(layout: FULL_WIDTH)
							}
						}
						bannerTitle
						bannerTitleShadow
						ourExperienceContent {
							style
							list
							_type
							_key
							children {
								marks
								text
								_type
								_key
							}
						}
						ourExperienceDetails {
							_key
							description
							number
						}
						ourExperienceImages {
							_key
							asset {
								gatsbyImageData(layout: CONSTRAINED)
							}
						}
						ourExperienceTitle
						ourExperienceTitleShadow
						ourStoreContent
						ourStoreTitle
						ourStoreTitleShadow
						ourStoreImage {
							asset {
								gatsbyImageData(layout: CONSTRAINED)
							}
						}
					}
				}
			}
		}
	}
`
